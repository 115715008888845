import React from 'react';

export function InvalidIcon({ width = '965', height = '835' }) {
  return (
    <svg version="1.1" viewBox="0 0 965 835" width={width} height={height}>
      <g transform="translate(-70 260) rotate(-35) scale(1.4)">
        <path
          className="path"
          fill="hsla(218, 96%, 10%, .5)"
          d="M562.121,259.312c-147.8,4.3-294.7,21.1-442.2,26c121.8-51.1,254-70.4,379.4-112.3c11.6-4,14.1-23.6,0-26
		c-118.6-21.4-234,27.8-351.8,23.9c72.8-32.4,153-46.2,227.1-76.5c12.2-5.2,11.9-25.4-3.399-24.8c-46.2,1.5-93.901,12.5-140.101,7.3
		c27.8-16.2,54.8-33.7,78.3-56.3c8.9-8.6-0.3-22.6-11.6-19.9c-22,4.9-48,9.2-62.4,27.5c-4.3,5.5-2.1,12.2,2.1,15.6
		c-15.6,9.5-31.5,18.7-47.7,27.5c-9.8,5.5-7,19.6,3.1,23c25.4,8.3,51.4,9.8,77.4,8.6c-59.7,17.7-119.6,34.3-174.1,65.8
		c-8.9,5.2-8.3,21.7,3.1,23.3c109.2,17.4,216.3-23.3,324.701-24.2c-127,35.2-257.301,57.8-375.201,120.9
		c-12.2,6.4-6.4,26.001,7,25.7c132.2-0.899,263.5-14.1,395.401-22c-49.301,13.801-98.801,26.301-148.4,37.601
		c-100.101,23-195.5,54.2-293.101,85.7c-14.4,4.6-12.5,28.199,3.7,27.199c79.3-4.6,155.8-24.199,233.5-37.899
		c81.4-14.4,170.4-23.9,254.601-27.2c-63.601,59.1-202,43.8-277.5,65.2c-13.5,3.7-13.5,23.3,0,27.2c30.9,8.899,61.2,10.1,92.1,9.5
		c-8.3,4-16.8,7-26,10.1c-5.8,1.8-10.4,7-10.4,13.5c0.6,19.6,2.4,38.9,4,58.4c1.5,18.1,29.7,18.1,28.2,0
		c-1.5-17.101-2.8-34.301-3.699-51.4c0.6,8.6,60.6-31.5,65.8-35.8c8.3-7.3,3.399-20.5-5.8-23.3
		c71-8.301,141.399-23.601,175.3-80.801c5.2-8.899-1.5-21.1-11.9-20.8c-82,1.8-164.3,15-246,23c-31.2,3.101-61.8,8.3-92.4,14.4
		c32.4-9.5,65.2-18.101,98.801-25.4c92.7-19.6,184.5-44.7,274.5-74.4C580.222,281.612,578.321,258.712,562.121,259.312z"
        />
      </g>
    </svg>
  );
}
